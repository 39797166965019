// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-denkmalmesse-2022-index-tsx": () => import("./../../../src/pages/denkmalmesse-2022/index.tsx" /* webpackChunkName: "component---src-pages-denkmalmesse-2022-index-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-feuertrutz-index-tsx": () => import("./../../../src/pages/feuertrutz/index.tsx" /* webpackChunkName: "component---src-pages-feuertrutz-index-tsx" */),
  "component---src-pages-gewinnspiel-tsx": () => import("./../../../src/pages/gewinnspiel.tsx" /* webpackChunkName: "component---src-pages-gewinnspiel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-lieferzeiten-tsx": () => import("./../../../src/pages/lieferzeiten.tsx" /* webpackChunkName: "component---src-pages-lieferzeiten-tsx" */),
  "component---src-pages-metrica-account-loeschen-tsx": () => import("./../../../src/pages/metrica-account-loeschen.tsx" /* webpackChunkName: "component---src-pages-metrica-account-loeschen-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview/[...].tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-widget-abfrage-tsx": () => import("./../../../src/pages/widget-abfrage.tsx" /* webpackChunkName: "component---src-pages-widget-abfrage-tsx" */),
  "component---src-pages-widget-brandschutzfenster-tsx": () => import("./../../../src/pages/widget-brandschutzfenster.tsx" /* webpackChunkName: "component---src-pages-widget-brandschutzfenster-tsx" */),
  "component---src-pages-widget-e-schiebefenster-tsx": () => import("./../../../src/pages/widget-e-schiebefenster.tsx" /* webpackChunkName: "component---src-pages-widget-e-schiebefenster-tsx" */),
  "component---src-templates-article-template-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-article-template-tsx" */),
  "component---src-templates-articles-index-template-articles-index-template-tsx": () => import("./../../../src/templates/ArticlesIndexTemplate/ArticlesIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-articles-index-template-articles-index-template-tsx" */),
  "component---src-templates-category-template-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-category-template-tsx" */),
  "component---src-templates-privacy-and-to-s-template-privacy-and-to-s-template-tsx": () => import("./../../../src/templates/PrivacyAndToSTemplate/PrivacyAndToSTemplate.tsx" /* webpackChunkName: "component---src-templates-privacy-and-to-s-template-privacy-and-to-s-template-tsx" */),
  "component---src-templates-sub-category-template-sub-category-template-tsx": () => import("./../../../src/templates/SubCategoryTemplate/SubCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-sub-category-template-sub-category-template-tsx" */)
}

